<template>
  <div>
    <p class="font-headers font-bold text-mdl text-grey-light mb-1.5">
      {{ title }}
    </p>
    <div v-if="hasDetails" class="flex flex-row flex-wrap ml-2">
      <p class="text-md text-grey-dark-1 xl:w-1/2 lg:w-full w-full pr-5">
        The {{ competenciesCount }} core competencies are listed from largest to smallest
        <span class="font-bold">delta</span>
        (Actual Score - Target Score). Associated colors highlight the range of
        gaps between them.
      </p>
    </div>
    <div v-if="hasSubcomp" class="flex flex-row flex-wrap ml-2">
      <p class="text-md text-grey-dark-1 xl:w-1/2 lg:w-full w-full pr-5">
        The {{ competenciesCount }} sub-competencies are listed from largest to smallest 
        <span class="font-bold">delta</span>
        (Actual Score - Target Score). Associated colors highlight the range of gaps
        between them.
      </p>
  </div>
    <div class="mx-2 my-3">
      <div class="flex flex-row w-full bg-grey-light-3 py-1 pl-2 rounded">
        <button
          v-if="hasSubcomp"
          class="flex flex-row items-center w-1/4 focus:outline-none"
          @click="sortCol('SUB-COMPETENCY', 0)"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">SUB-COMPETENCY</p>
          <img
            src="../../assets/img/delta-table/caret.svg"
            class="transition-all"
            :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
          />
        </button>
        <button
          class="flex flex-row items-center focus:outline-none"
          :class="hasDetails ? 'w-1/2' : 'w-1/4'"
          @click="sortCol('CORE COMPETENCY', 1)"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">CORE COMPETENCY</p>
          <img
            src="../../assets/img/delta-table/caret.svg"
            class="transition-all"
            :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
          />
        </button>
        <button
          class="flex flex-row items-center w-1/6 justify-center focus:outline-none"
          @click="sortCol('ACTUAL SCORE', 2)"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">ACTUAL</p>
          <img
            src="../../assets/img/delta-table/caret.svg"
            class="transition-all"
            :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
          />
        </button>
        <button
          class="flex flex-row items-center w-1/6 justify-center focus:outline-none"
          @click="sortCol('TARGET', 3)"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">TARGET</p>
          <img
            src="../../assets/img/delta-table/caret.svg"
            class="transition-all"
            :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null"
          />
        </button>
        <button
          class="flex flex-row items-center w-1/6 justify-center focus:outline-none"
          @click="sortCol('DELTA', 4)"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">DELTA</p>
          <img
            src="../../assets/img/delta-table/caret.svg"
            class="transition-all"
            :class="rotationMatrix[4] !== 1 ? 'transform rotate-180' : null"
          />
        </button>
      </div>
      <div v-for="(item, index) in subArray" :key="item.competency + index">
        <TableItem :item="item" :type="type" />
        <div class="border border-grey-light-1 border-b-0" />
      </div>
    </div>
    <div class="flex justify-end mr-2">
      <Button
        :text="buttonText"
        size="medium"
        type="secondary"
        :iconRight="arrow"
        :rotateArrow="rotateArrow"
        @click.native="onClick"
      />
    </div>
  </div>
</template>
<script>
import TableItem from "./TableItem";
import Button from "../Button/Button";
import arrow from "@/assets/img/icons/arrow-down.svg";

export default {
  name: "DeltaTable",
  components: { TableItem, Button },
  data: () => ({
    arrow,
    nrOfEl: 5,
    table: [],
    rotationMatrix: [1, 1, 1, 1, 1],
  }),
  props: {
    tableData: { type: Array, default: null },
    type: { type: String, default: "competency" },
    title: { type: String },
    onSortingChange: {type: Function, default: (sortingValue, sortingDirection) => undefined}
  },
  watch: {
    tableData(val) {
      this.orderedTable(val);
    },
  },
  computed: {
    hasDetails() {
      return this.type === "competency";
    },
    hasSubcomp() {
      return this.type === "subcompetency";
    },
    subArray() {
      return this.table.slice(0, this.nrOfEl);
    },
    buttonText() {
      return this.nrOfEl < this.table.length ? "Show More" : "Show Less";
    },
    rotateArrow() {
      return this.nrOfEl >= this.table.length;
    },
    competenciesCount() {
      return this.tableData?.length;
    }
  },
  methods: {
    orderedTable(table) {
      const mappedTable = table
        .map((item) => ({
          ...item,
          delta: (item.actual - item.target).toFixed(2),
        }))
        .sort((a, b) => b.delta - a.delta);
      this.table = mappedTable.map((item, index) => ({
        ...item,
        color: this.coloring(((table.length - index) / table.length) * 100),
      }));
    },
    coloring(percentage) {
      var r,
        g,
        b = 0;
      if (percentage < 50) {
        r = 255;
        g = Math.round(5.1 * percentage);
      } else {
        g = 255;
        r = Math.round(510 - 5.1 * percentage);
      }
      var h = r * 0x10000 + g * 0x100 + b * 0x1;
      return "#" + ("000000" + h.toString(16)).slice(-6);
    },
    resetRotationMatrix(idx) {
      this.rotationMatrix.map((_, index, array) =>
        index === idx ? (array[index] *= -1) : (array[index] = 1)
      );
    },
    sortCol(col, idx) {
      this.resetRotationMatrix(idx);
      let sortingKey = ''
      switch (col) {
        case "SUB-COMPETENCY":
          this.table.sort((a, b) => {
            const result = a.subcompetency.localeCompare(b.subcompetency);
            return this.rotationMatrix[idx] * result;
          });
          sortingKey = 'NAME'
          break;
        case "CORE COMPETENCY":
          this.table.sort((a, b) => {
            if(a.competency !== b.competency) {
              return this.rotationMatrix[idx] * a.competency.localeCompare(b.competency);
            }
            return a.subcompetency.localeCompare(b.subcompetency);
          });
          sortingKey = this.type === 'competency' ? 'NAME' :  'PARENT_COMPETENCY_NAME'
          break;
        case "ACTUAL SCORE":
          this.table.sort(
            (a, b) => {
              if(a.actual !== b.actual) {
               return this.rotationMatrix[idx] * (a.actual - b.actual)
              }
              if(a.competency !== b.competency) {
                return a.competency.localeCompare(b.competency)
              }
              return a.subcompetency.localeCompare(b.subcompetency);
            }
          );
          sortingKey = 'SCORE'
          break;
        case "TARGET": 
          this.table.sort(
            (a, b) => {
              if(a.target !== b.target) {
               return this.rotationMatrix[idx] * (a.target - b.target)
              }
              if(a.competency.localeCompare(b.competency) !== 0) {
                return a.competency.localeCompare(b.competency)
              }
              return a.subcompetency.localeCompare(b.subcompetency);
            }
          );
          sortingKey = 'TARGET'
          break;
        case "DELTA":
          this.table.sort(
            (a, b) => {
              if(a.delta !== b.delta) {
                return this.rotationMatrix[idx] * (a.delta - b.delta)
              }
              if(a.competency.localeCompare(b.competency) !== 0) {
                return a.competency.localeCompare(b.competency)
              }
              return a.subcompetency.localeCompare(b.subcompetency);
            }
          );
          sortingKey = 'DELTA'
          break;
        default:
          break;
      }
      this.onSortingChange(sortingKey, this.rotationMatrix[idx] === -1 ? 2 : 1)
    },
    onClick() {
      if (this.nrOfEl < this.table.length) {
        this.nrOfEl = this.table.length;
      } else this.nrOfEl = 5;
    },
  },
  mounted() {
    this.orderedTable(this.tableData);
  },
};
</script>
