<template>
  <div class="flex">
    <div class="flex flex-wrap w-3/4">
      <div
        v-for="item in this.legend"
        :key="item.label"
        class="flex flex-row items-center w-1/3 mb-2"
      >
        <div
          class="h-1 w-3 mr-0.4 rounded"
          :style="`backgroundColor: ${item.color}`"
        />
        <p class="text-sm-2 text-grey-light">{{ item.label }}</p>
      </div>
    </div>
    <div class="w-1/4">
      <div class="flex flex-row mb-2">
        <img src="../../assets/img/icons/legendRed.svg" />
        <p class="text-sm-2 text-grey-light">Company Target</p>
      </div>
      <div class="flex flex-row mb-2" v-if="withManagerial">
        <img src="../../assets/img/icons/legendGray.svg" />
        <p class="text-sm-2 text-grey-light">Managerial Assessment Score</p>
      </div>
      <div class="flex flex-row mb-2" v-if="withTeam">
        <img src="../../assets/img/icons/legendLightGray.svg" />
        <p class="text-sm-2 text-grey-light">
          Average Company Assessment Score
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "StaticLegend",
  props: ["withManagerial", "withTeam", "legend"],
};
</script>
