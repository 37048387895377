<template>
  <div class="flex gap-0.4 flex-row w-full py-2 pl-2">
    <div v-if="hasSubcomp" class="w-1/4">
      <p class="text-md text-grey-dark-1 font-bold">
        {{ item.subcompetency.replaceAll("&nbsp;", " ") }}
      </p>
    </div>
    <div :class="hasSubcomp ? 'w-1/4' : 'w-1/2'">
      <p
        class="text-md text-grey-dark-1"
        :class="hasSubcomp ? '' : 'font-bold'"
      >
        {{ item.competency }}
      </p>
    </div>

    <div class="flex w-1/6 justify-center">
      <p class="text-md text-grey-dark-2">
        {{ item.actual.toFixed(2) }}
      </p>
    </div>
    <div class="flex w-1/6 justify-center">
      <p class="text-md text-grey-dark-2">{{ item.target.toFixed(2) }}</p>
    </div>
    <div class="flex flex-row w-1/6 justify-center">
      <p class="text-md text-grey-dark-2">
        {{ item.delta > 0 ? "+" : "" }}{{ item.delta }}
      </p>
      <div
        class="w-10 h-10 rounded-full ml-1 deltaStatus"
        :style="`background-color: ${item.color}`"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "TableItem",
  props: {
    item: { type: Object, default: null },
    type: { type: String, default: null },
  },
  computed: {
    hasSubcomp: function() {
      return this.type === "subcompetency";
    },
  },
};
</script>
<style lang="scss" scoped>
.deltaStatus {
  margin-top: 5px;
}
</style>
